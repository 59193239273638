<template scoped>
  <Panel-CRUD
    ref="crud"
    :guid="guid"
    :endpoint="endpoint"
    :toPage="toPage"
    :resourceServer="resourceServer"
    :resourceId="resourceId"
    title="Solution"
    :create="create"
    :resourceServerApi="false"
    apiPath="Backend/Solution"
  >
    <template>
      <el-table-column prop="name" label="Name" width="300"> </el-table-column>
      <el-table-column prop="enable" label="Live Mode">
        <template slot-scope="scope">
          <el-switch
            @change="$refs.crud.setEnable(scope.row)"
            v-model="scope.row.enable"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="action" label="Action">
        <template slot-scope="scope">
          <el-row>
            <el-col :span="24">
              <el-tooltip
                class="item"
                effect="dark"
                content="Edit"
                placement="right"
              >
                <el-button
                  @click="edit(scope.row)"
                  icon="el-icon-edit"
                  circle
                ></el-button>
              </el-tooltip>
            </el-col>
            <el-col :span="24" :style="{ marginTop: '10px' }">
              <el-tooltip
                class="item"
                effect="dark"
                content="Role"
                placement="right"
              >
                <el-button
                  @click="role(scope.row)"
                  icon="el-icon-guide"
                  circle
                ></el-button>
              </el-tooltip>
            </el-col>
            <el-col :span="24" :style="{ marginTop: '10px' }">
              <el-tooltip
                class="item"
                effect="dark"
                content="Consent"
                placement="right"
              >
                <el-button
                  @click="consent(scope.row)"
                  icon="el-icon-guide"
                  circle
                ></el-button>
              </el-tooltip>
            </el-col>
            <el-col :span="24" :style="{ marginTop: '10px' }">
              <el-tooltip
                class="item"
                effect="dark"
                content="Copy Id"
                placement="right"
              >
                <el-button
                  @click="$refs.crud.copyId(scope.row)"
                  icon="el-icon-document-copy"
                  circle
                ></el-button>
              </el-tooltip>
            </el-col>
            <el-col :span="24" :style="{ marginTop: '10px' }">
              <el-tooltip
                class="item"
                effect="dark"
                content="Delete"
                placement="right"
              >
                <el-button
                  type="danger"
                  @click="$refs.crud.remove(scope.row)"
                  icon="el-icon-delete"
                  circle
                ></el-button>
              </el-tooltip>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </template>
  </Panel-CRUD>
</template>
<script>
export default {
  props: {
    guid: String,
    endpoint: String,
    toPage: Function,
    resourceServer: String,
    resourceId: String,
  },
  components: {
    "Panel-CRUD": () =>
      import("cms_infrastructure_web/src/sdk/Admin/CRUD/CRUD-List.vue"),
  },
  methods: {
    edit: function (item) {
      this.toPage("components/Solution/Portal-Solution-CreateUpdate.vue", {
        id: item.id,
      });
    },
    create: function () {
      this.toPage("components/Solution/Portal-Solution-CreateUpdate.vue");
    },
    role: function (item) {
      this.toPage("components/SolutionRole/Portal-SolutionRole.vue", {
        filter: [
          {
            id: "SolutionId",
            name: "SolutionId",
            value: item.id,
            friendlyValue: item.name,
          },
        ],
      });
    },
    consent: function (item) {
      this.toPage("components/SolutionConsent/Portal-SolutionConsent.vue", {
        filter: [
          {
            id: "SolutionId",
            name: "SolutionId",
            value: item.id,
            friendlyValue: item.name,
          },
        ],
      });
    },
    emulator: function (item) {
      var $this = this;

      fetch(`${this.endpoint}/api/Security/Subscription?Page=1&PageSize=1`)
        .then(function (request) {
          return request.json();
        })
        .then(function (response) {
          var subscriptionCount = response.result.result;

          if (subscriptionCount.length > 0) {
            var token = subscriptionCount[0].token;

            $this.$plugin.application.startChildWindow({
              guid: $this.guid,
              path:
                "applications/user/j1/business/authorization-hub-sample/AuthorizationHub-Sample-Instance",
              parameter: {
                name: item.name,
                endpoint: $this.endpoint,
                solutionId: item.id,
                subscriptionToken: token,
              },
            });
          }
        });
    },
  },
};
</script>